import styled from '@emotion/styled';
import { BodySmall, BodyXS } from '../../../text';
import { IconButton } from '../../button';

export const MessageWrapper = styled.div``;

export const Wrapper = styled.div<{
  isHelpButtonVisible: boolean;
  warning: boolean;
  error: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 4px;

  ${MessageWrapper} {
    margin-left: ${(props): number => (props.isHelpButtonVisible ? 24 : 0)}px;
  }

  ${BodySmall},
  ${BodyXS} {
    color: ${({ warning, error }): string => {
      if (error) {
        return 'var(--error-error)';
      }
      if (warning) {
        return '#C68B12';
      }
      return 'rgba(0,0,0,0.5)';
    }};
  }
`;

export const IconButtonStyled = styled(IconButton)<{
  warning: boolean;
  error: boolean;
}>`
  position: absolute;
  top: -4px;
  transform: translateX(-12px);
  width: 40px;
  height: 40px;

  > svg {
    fill: ${({ theme, error, warning }): string => {
      if (error) {
        return theme.state.error;
      }
      if (warning) {
        return theme.state.warning;
      }
      return theme.foreground.secondary;
    }};
  }
`;
