import { Configuration, FrontendApi, type Session } from '@ory/client';
import type { ReduxState } from 'imddata';
import { useCreateEntity } from 'imddata';
import { loadLoggedUser } from 'imddata/actionTypes/users';
import config from 'imdconfig';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

const ory = new FrontendApi(
  new Configuration({
    basePath: config.kratosUrl,
    baseOptions: {
      withCredentials: true,
    },
  })
);
export const KratosGateway = process.env.AUTH_APP_URL
  ? ({ children, loader }: { children: any; loader: any }): any => {
      const [loaded, setLoaded] = useState(false);

      const loggedUserRequested = useSelector(
        (state: ReduxState) => state.auth && state.auth.isRequested
      );
      const hasProfile = useSelector(
        (state: ReduxState) => state.auth && !!state.auth.profile
      );

      const dispatch = useDispatch();

      const { state } = useLocation<{ from?: { pathname: string } }>();
      const { createEntry, request } = useCreateEntity({
        entity: 'registerAction',
      });

      const from =
        state && state.from && state.from.pathname !== '/' ? state.from : '';
      const [session, setSession] = useState<Session>();
      useEffect(() => {
        if (!loaded) {
          ory
            .toSession()
            .then((d) => {
              setSession(d.data);
              setLoaded(true);
            })
            .catch(() => {
              setLoaded(true);
            });
        }
      }, [loaded]);

      useEffect(() => {
        if (!session || !session.active) return;

        if (!loggedUserRequested) {
          dispatch(loadLoggedUser());
        }
      }, [session]);

      useEffect(() => {
        if (loaded && !session?.active) {
          const redirect: string = `${process.env.AUTH_APP_URL}/signin?return_to=${process.env.APP_URL}${from}`;

          window.location.href = redirect;
        }
      }, [loaded, !!session]);

      useEffect(() => {
        if (loggedUserRequested && !hasProfile) {
          createEntry();
        }
      }, [loggedUserRequested, hasProfile]);

      useEffect(() => {
        dispatch(loadLoggedUser());
      }, [request.created]);

      if (!loaded || !hasProfile) return loader;

      return children;
    }
  : ({ children }: any) => children;
