//
import io from 'socket.io-client';
import config from 'imdconfig';
import { getAuth } from './auth';

export const channels = {
  general: 'general',
  admins: 'general_admins',
};

export default function connect(channel = channels.admins) {
  const socket = io(config.socketUrl, {
    path: config.socketPath,
    withCredentials: true,
    upgrade: false,
    transports: ['websocket'],
    protocol: 'https',
  });

  const authFromLocalStorage = getAuth();
  return new Promise((resolve) => {
    socket.on('connect', () => {
      socket.emit(
        'subscribe-user',
        {
          user: {
            ...(authFromLocalStorage?.accessToken
              ? {
                token: authFromLocalStorage?.accessToken,
              }
              : {}),
            broadcastChannel: channel,
          },
        },
        () => {
          // callback
        }
      );
      resolve(socket);
    });
  });
}
