import styled from '@emotion/styled';
import Markdown from 'markdown-to-jsx';
import React, { createContext, useContext, useCallback } from 'react';
import { Caption, BodySmall, BodyXS } from '../../../text';
import { IconsCollection } from '../../icon';
import Icons from '../../icon/Icons';
import SVGIcon from '../../icon/SVGIcon';
import Checkbox from '../../toggle/Checkbox';
import { Wrapper, MessageWrapper, IconButtonStyled } from './styles';

export type HelperTextProps = {
  text?: string | React.ReactNode;
  warningText?: string | boolean;
  errorText?: string | boolean | React.ReactNode;
  onClickHelperButton?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

type Props = React.ComponentProps<'div'> & HelperTextProps;

const getText = ({
  text,
  warningText,
  errorText,
}: HelperTextProps): string | undefined | React.ReactNode => {
  if (
    errorText === true ||
    errorText === '[FUR]' ||
    errorText === 'required' ||
    warningText === true
  ) {
    return text;
  }

  if (typeof errorText === 'boolean' && !warningText) {
    return text;
  }
  return errorText || warningText || text;
};

const CheckboxStyled = styled(Checkbox)`
  transform: scale(0.75);
  position: relative;
  top: -2px;
  margin: 0 4px 0 0;
  min-height: auto;

  input {
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px #333333;
  }
`;

const MessageRow = styled.div`
  align-items: flex-start;
  display: flex;
  margin-bottom: 8px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

type Issues = {
  title?: string;
  list: Array<{ message: string; checked?: boolean }>;
} | null;

type CheckIssue = (idx: number, v: boolean) => void;

export const IssueListContext = createContext<{
  issues: Issues;
  onCheckIssue?: CheckIssue;
}>({
  issues: null,
});

IssueListContext.displayName = 'IssueListContext';

const IssueListWrapper = styled.div<{ error?: boolean }>`
  border: 1px solid;
  margin-top: 4px;
  padding: 12px 8px 8px;
  border-radius: 3px;

  border-color: ${({ theme, error }): string => {
    if (error) {
      return theme.state.error;
    }
    return theme.foreground.secondary;
  }};
  ${BodySmall},
  ${Caption} {
    color: ${({ theme, error }): string => {
      if (error) {
        return theme.state.error;
      }
      return theme.foreground.secondary;
    }};
  }
`;
const IssueListHeader = styled.div<{ error?: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  ${BodySmall} {
    display: inline-block;
    margin-left: 8px;
  }
  svg {
    fill: ${({ theme, error }): string => {
      if (error) {
        return theme.state.error;
      }
      return theme.foreground.secondary;
    }};
  }
`;

export const IssueList: React.FC<{
  error: boolean | string;
  issues?: Issues;
  onCheckIssue?: CheckIssue;
}> = ({ error, issues, onCheckIssue }) => {
  if (issues) {
    return (
      <IssueListWrapper
        error={!!error}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      >
        <IssueListHeader error={!!error}>
          <SVGIcon d={Icons.states.error} size={16} />
          <BodySmall>{issues.title}</BodySmall>
        </IssueListHeader>
        {issues.list.map(({ message: requestMessage, checked }, idx) => (
          <MessageRow key={idx}>
            {onCheckIssue ? (
              <CheckboxStyled
                name={`check-issue-${idx}`}
                checked={!!checked}
                onCheck={(e, v) => {
                  onCheckIssue(idx, v);
                }}
              />
            ) : null}
            <Caption>
              <span
                style={{ fontWeight: 500, color: '#333', marginRight: 4 }}
              >{`${idx + 1}.`}</span>
              <Markdown
                options={{
                  forceInline: true,
                  overrides: {
                    a: {
                      props: {
                        target: '_blank',
                        rel: 'noopener noreferrer',
                      },
                    },
                  },
                }}
              >
                {requestMessage}
              </Markdown>
            </Caption>
          </MessageRow>
        ))}
      </IssueListWrapper>
    );
  }

  return null;
};

const HelperText: React.FC<Props> = ({
  text,
  warningText,
  onClickHelperButton,
  errorText,
  className,
  style,
  ...props
}) => {
  const { issues, onCheckIssue } = useContext(IssueListContext);
  const handleSafePointerEvent = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
    },
    []
  );

  const finalText = getText({ warningText, errorText, text });

  return (
    <>
      {finalText || onClickHelperButton ? (
        <Wrapper
          warning={!!warningText}
          error={!!errorText}
          isHelpButtonVisible={!!onClickHelperButton}
          onClick={handleSafePointerEvent}
          onMouseDown={handleSafePointerEvent}
          className={className}
          style={style}
          {...props}
        >
          {onClickHelperButton && (
            <IconButtonStyled
              tabIndex={-1}
              newIcon={IconsCollection.info}
              error={!!errorText}
              warning={!!warningText}
              iconProps={{ size: 16 }}
              onClick={onClickHelperButton}
            />
          )}
          <MessageWrapper>
            <BodyXS>{finalText}</BodyXS>
          </MessageWrapper>
        </Wrapper>
      ) : null}
      <IssueList
        issues={issues}
        onCheckIssue={onCheckIssue}
        error={!!errorText && errorText === '[FUR]'}
      />
    </>
  );
};

export default HelperText;
